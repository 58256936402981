import user from '@/ajax/user';
import { mapState,mapActions } from 'vuex';
import axios from "axios";
import EventBus from '@/helpers/event-bus';
import moment from 'moment';

export default{
  data(){
    return{
      // permissions:"",
      live_time:{
        hour:0,
        minute:0,
        second:0,
      },
      Interval1:0,
      Interval2:0,
    }
  },
  methods: {
    ...mapActions('userStore', ['setCurrentUser','logoutUser']),
    ...mapActions('common', ['setCurrentPage']),

    //check permission to access page
    getUserPermissions() {
      return user.getCurrentUser().then((result) => {
        // fetch user roles and permissions
        this.user_role_checker = result.data.user_role
        const permission_array = [];
        for(var i=0;i<result.data.user_role_details.roles_permissions.length;i++){
            permission_array[i] = result.data.user_role_details.roles_permissions[i].permission_details.module
        }
        // set currentuser data
        this.setCurrentUser(result.data);
        EventBus.$emit("permission-callback", true);
        localStorage.setItem('permissions',permission_array);
        localStorage.setItem("current_user", result.data.id);
        localStorage.setItem("user_role", result.data.user_role);
        localStorage.setItem("user_type", result.data.user_type);
      });
    },
    CreatedAndUpdatedAt(created_at,updated_at){
      var created_history = '';
      var updated_history = '';
      if(created_at){
        var created_at = moment.utc(created_at).local().format()
        var now = moment().local().format();
        var ms = moment(now).diff(moment(created_at));

        // convert milisecond to hours
        var minutes = Math.trunc(ms/60000);
        var hours = Math.trunc(minutes/60);
        var days = Math.trunc(hours/24);
        var months = Math.trunc(days/30.417);
        var years = Math.trunc(months/12);

        if(years < 1){
          if(months < 1){
            if(days < 1){
              if(hours < 1){
                if(minutes > 10){
                  created_history =  " Created "+minutes+" minutes ago";
                }else{
                  created_history = "Created recently";
                }
              }else{
                created_history =  " Created "+hours+" hours ago";
              }
            }else{
              created_history =  "Created "+days+" days ago";
            }
          }else{
            created_history =  "Created "+months+" months ago";
          }
        }else{
          created_history =  "Created "+years+" years ago";
        }
      }
      if(updated_at){
        var updated_at = moment.utc(updated_at).local().format()
        var now = moment().local().format();
        // console.log("now",now);
        // console.log("updated_at",updated_at)
        var ms = moment(now).diff(moment(updated_at));
        // convert milisecond to hours
        var minutes = Math.trunc(ms/60000);
        var hours = Math.trunc(minutes/60);
        var days = Math.trunc(hours/24);
        var months = Math.trunc(days/30.417);
        var years = Math.trunc(months/12);

        if(years < 1){
          if(months < 1){
            if(days < 1){
              if(hours < 1){
                if(minutes > 10){
                  updated_history =  " Updated "+minutes+" minutes ago";
                }else{
                  updated_history = " Updated recently"
                }
              }else{
                updated_history =  " Updated "+hours+" hours ago";
              }
            }else{
              updated_history =  " Updated "+days+" days ago";
            }
          }else{
            updated_history =  " Updated "+months+" months ago";
          }
        }else{
          updated_history =  " Updated "+years+" years ago";
        }
      }
      return created_history + updated_history;
    },
    ConvertMinutesToTime(value) {
      if(value){
        var hours = parseInt(Math.floor(value/60));
        var minutes = parseInt(Math.floor(value%60));

        if(hours == 0)
        {
          return minutes+"m"
        }else{
          if(minutes == 0){
            return hours+"h"
          }else{
            return hours+"h "+minutes+"m"
          }
        }
      }else{
        return "Not Approved"
      }
    },
    ConvertMinutesToTime2(value) {
      if(value>=0){
        var hours = parseInt(Math.floor(value/60));
        var minutes = parseInt(Math.floor(value%60));

        if (hours == 0){
          return "00" + ':' + (minutes < 10 ?  "0"+minutes : minutes)
        }
        else {
          return (hours < 10 ? "0"+hours : hours) + ':' + (minutes < 10 ? "0"+minutes : minutes)

        }
      }
    },
    TodayTimeSpent(){
      var start_time = null
      var time_spent = null
      var time = this;
      user.getLiveTimeSpent().then((result) => {
        if(result.data.code == 200){
          if(result.data.data.start_time != null && result.data.data.time_spent != null){
            // clearInterval(this.Interval2);
            // console.log("both not null")
            start_time = result.data.data.start_time
            time_spent = result.data.data.time_spent
            // this.Interval1 = setInterval(function () {
                time.live_time.hour = Math.floor(((new Date(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))-new Date(moment(start_time).format("YYYY-MM-DD HH:mm:ss")))/60000+time_spent) / 60)
                time.live_time.minute = Math.floor(((new Date(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))-new Date(moment(start_time).format("YYYY-MM-DD HH:mm:ss")))/60000+time_spent) % 60)
                time.live_time.second = Math.round(((((new Date(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))-new Date(moment(start_time).format("YYYY-MM-DD HH:mm:ss")))/60000)+time_spent)-((time.live_time.hour*60)+time.live_time.minute))*60)
            // }, 1000);
          }
          if(result.data.data.start_time != null && result.data.data.time_spent == null){
            // clearInterval(this.Interval1);
            // console.log("only start time is exist")
            start_time = result.data.data.start_time
            // this.Interval2 = setInterval(function () {
              time.live_time.hour = Math.floor(((new Date(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))-new Date(moment(start_time).format("YYYY-MM-DD HH:mm:ss")))/60000) / 60)
              time.live_time.minute = Math.floor(((new Date(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))-new Date(moment(start_time).format("YYYY-MM-DD HH:mm:ss")))/60000) % 60)
              time.live_time.second = Math.round((((new Date(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))-new Date(moment(start_time).format("YYYY-MM-DD HH:mm:ss")))/60000)-((time.live_time.hour*60)+time.live_time.minute))*60)
            // }, 1000);

          }
          if(result.data.data.start_time == null && result.data.data.time_spent != null){
            // console.log("only time spent is exist")
            // clearInterval(this.Interval1);
            // clearInterval(this.Interval2);
            time_spent = result.data.data.time_spent
            time.live_time.hour = Math.floor(time_spent/60)
            time.live_time.minute = Math.floor(time_spent%60)
            time.live_time.second = Math.round((time_spent-((time.live_time.hour*60)+time.live_time.minute))*60)
          }
          return time.live_time
        }
      });
   }
  },
  computed: {
    ...mapState('userStore', ['currentUser'])
  }
}
