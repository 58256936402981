<template>
  <!-- Begin Page Content -->
  <div class="">
    <div class="card shadow-sm border-0 my-4">
      <div class="card-body">
        <div class="text-md-start text-center">
          <h4 class="m-0 font-weight-bold text-primary">
            Daily Attendance Report
          </h4>
        </div>

        <div
          class="d-flex justify-content-md-between justify-content-center gap-md-0 gap-2 flex-md-nowrap flex-wrap align-items-center my-3"
        >
          <!-- first block -->
          <div class="d-flex justify-content-center align-items-center gap-2">
            <!-- left btn -->
            <button
              class="btn btn-primary btn-sm rounded-start"
              @click="ChangeDate('pre')"
            >
              <i class="fa fa-arrow-left"></i>
            </button>
            <!-- datapicker -->
            <datepicker
              @input="FilterApply()"
              type="date"
              :disabled-dates="disabledDates"
              :format="'yyyy-MM-dd'"
              :bootstrap-styling="true"
              :value="dateVal"
              v-model="reqObj.searched_date"
              required
            ></datepicker>
            <!-- right btn -->
            <button
              class="btn btn-primary btn-sm rounded-end"
              @click="ChangeDate('next')"
            >
              <i class="fa fa-arrow-right"></i>
            </button>
          </div>
          <!-- second block -->
          <div class="btn-group" role="group">
            <button
              type="button"
              class="btn btn-sm"
              :class="
                reqObj.present_absent_filter == 'present'
                  ? 'btn-primary'
                  : 'btn-outline-primary bg-white text-primary'
              "
              data-toggle="pill"
              id="pills-present-tab"
              href="#pills-present"
              role="tab"
              aria-controls="pills-present"
              aria-selected="false"
              @click="FilterApply('present')"
            >
              Present
            </button>
            <button
              type="button"
              class="btn btn-sm"
              :class="
                reqObj.present_absent_filter == 'absent'
                  ? 'btn-primary'
                  : 'btn-outline-primary bg-white text-primary'
              "
              data-toggle="pill"
              id="pills-absent-tab"
              href="#pills-absent"
              role="tab"
              aria-controls="pills-absent"
              aria-selected="false"
              @click="FilterApply('absent')"
            >
              Absent
            </button>
            <button
              type="button"
              class="btn btn-sm"
              :class="
                reqObj.present_absent_filter == 'not started'
                  ? 'btn-primary'
                  : 'btn-outline-primary bg-white text-primary'
              "
              data-toggle="pill"
              id="pills-not-started-tab"
              href="#pills-not-started"
              role="tab"
              aria-controls="pills-not-started"
              aria-selected="false"
              @click="FilterApply('not started')"
            >
              Not Yet Started
            </button>
            <button
              type="button"
              class="btn btn-sm"
              :class="
                reqObj.present_absent_filter == 'all'
                  ? 'btn-primary'
                  : 'btn-outline-primary bg-white text-primary'
              "
              data-toggle="pill"
              id="pills-all-tab"
              href="#pills-all"
              role="tab"
              aria-controls="pills-all"
              aria-selected="true"
              @click="FilterApply('all')"
            >
              All
            </button>
          </div>
          <!-- third block -->
          <div>
            <multiselect
              v-model="reqObj.filtered_users"
              :hide-selected="true"
              :options="active_users_list"
              :multiple="true"
              style="width: 18rem"
              placeholder="Select one or more User"
              label="first_name"
              track-by="id"
              :custom-label="custom_label"
              group-label="label"
              group-values="users_data"
              :group-select="true"
              @input="FilterApply()"
              :close-on-select="false"
            >
              <template #option="props">
                {{
                  Object.keys(props.option).includes("$groupLabel")
                    ? props.option["$groupLabel"]
                    : props.option.middle_name != null
                    ? `${props.option.first_name} ${props.option.middle_name}
                ${props.option.last_name}`
                    : `${props.option.first_name} ${props.option.last_name}`
                }}
              </template>
            </multiselect>
          </div>
        </div>
        <h6 class="text-end fw-normal">Count : {{ rows.length }}</h6>
        <div class="table-responsive">
          <!--show not started users first then late and then on time users so show per has_started_late-->
          <vue-good-table
            :rows="rows"
            :columns="columns"
            :sort-options="{
              enabled: true,
              initialSortBy: { field: 'has_started_late', type: 'desc' },
            }"
            styleClass="vgt-table no-border"
          >
            <div slot="table-actions" v-if="false">
              <div class="container-fluid p-2 mt-1">
                <div class="row">
                  <div class="col" style="width: 300px !important">
                    <label
                      >Total Users :
                      <b>{{ this.get_info.total_users }}</b></label
                    >
                  </div>
                  <div class="col" style="width: 300px !important">
                    <label
                      >Present : <b>{{ this.get_info.total_present }}</b></label
                    >
                  </div>
                  <div class="col" style="width: 300px !important">
                    <label
                      >Absent : <b>{{ this.get_info.total_absent }}</b></label
                    >
                  </div>
                  <div class="col" style="width: 300px !important">
                    <label
                      >Not Started :
                      <b>{{ this.get_info.total_not_started }}</b></label
                    >
                  </div>
                </div>
              </div>
            </div>
            <!--Style the rows based in whether user is late/on time/not started-->
            <template slot="table-row" slot-scope="props">
              <span
                v-if="props.row.has_started_late == 0"
                style="color: #32a852"
              >
                {{ props.formattedRow[props.column.field] }}
              </span>
              <span v-else style="color: #f00">
                {{ props.formattedRow[props.column.field] }}
              </span>
              <span
                v-if="props.row.has_started_late == 0"
                style="color: #32a852"
              >
                <span v-if="props.column.label == 'Reported Hours'">
                  <span v-if="props.row.report_time == null">
                    <label>-</label>
                  </span>
                  <span v-else>
                    <span
                      style="color: rgb(50, 168, 82); cursor: pointer"
                      v-on:click.prevent="
                        get_report_by_user_and_date(
                          props.row.user_id,
                          props.row.date
                        )
                      "
                    >
                      <i class="fa fa-eye"></i>
                      {{ ConvertMinutesToTime(props.row.report_time) }}
                    </span>
                  </span>
                </span>
              </span>
              <span v-else>
                <span v-if="props.column.label == 'Reported Hours'">
                  <span v-if="props.row.report_time == null">
                    <label>-</label>
                  </span>
                  <span v-else>
                    <span
                      style="cursor: pointer; color: rgb(255, 0, 0)"
                      v-on:click.prevent="
                        get_report_by_user_and_date(
                          props.row.user_id,
                          props.row.date
                        )
                      "
                    >
                      <i class="fa fa-eye"></i>
                      {{ ConvertMinutesToTime(props.row.report_time) }}
                    </span>
                  </span>
                </span>
              </span>
            </template>
          </vue-good-table>
        </div>
        <div class="d-flex justify-content-start align-items-center gap-2 mt-2">
          <button class="btn btn-primary" @click="pdfDownload">Pdf</button>
          <button class="btn btn-primary ms-2" @click="excelDownload">
            Excel
          </button>
        </div>
        <div
          class="modal fade"
          tabindex="-1"
          role="dialog"
          id="reportDetailsModal"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="text-dark m-0" id="exampleModalLabel">
                  Logged Hours
                </h3>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body" style="overflow-y: auto">
                <table class="table p-0 m-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">Project Name</th>
                      <th scope="col">Task Summary</th>
                      <th scope="col">Report Summary</th>
                      <th scope="col">Spent Time</th>
                      <th scope="col">Approved Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in report_rows" :key="data.id">
                      <td>{{ data["project_name"] }}</td>
                      <td>
                        <read-more
                          more-str="read more"
                          :text="data['task_summary']"
                          less-str="read less"
                          :max-chars="100"
                        ></read-more>
                      </td>
                      <td>
                        <read-more
                          more-str="read more"
                          :text="data['report_summary']"
                          less-str="read less"
                          :max-chars="100"
                        ></read-more>
                        <a
                          v-if="data['commit_link'] != null"
                          :href="data['commit_link']"
                          >Commit link</a
                        >
                      </td>
                      <td>
                        {{ ConvertMinutesToTime(data["new_spent_time"]) }}
                      </td>
                      <td>
                        {{ ConvertMinutesToTime(data["new_approved_time"]) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <button class="btn btn-outline-secondary btn-sm float-end mt-2" data-bs-dismiss="modal">Close</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Main Content -->
</template>
<script>
import Vue from "vue";
import axios from "axios";
import user from "@/ajax/user";
import moment from "moment";
moment.locale("en");
import { mapState, mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import VueDatetimePickerJs from "vue-date-time-picker-js";
import myMixin from "@/mixins/Permission";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { jsontoexcel } from "vue-table-to-excel";
import { utils, writeFileXLSX, writeFile } from "xlsx-js-style";
import ReadMore from "vue-read-more";
import Multiselect from "vue-multiselect";
Vue.use(ReadMore);

export default {
  name: "Attendance",
  mixins: [myMixin],
  components: {
    Multiselect,
    Datepicker,
    datePicker: VueDatetimePickerJs,
  },
  data: function () {
    return {
      permissions: window.localStorage.getItem("permissions"),
      dateVal: "",
      siteLogReq: {
        site_name: "daily_attendance",
      },
      columns: [
        {
          label: "Name",
          //concate first and last name
          field: "full_name",
          title: "Name",
          dataKey: "UserModel.getUserModel()",
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Date",
          field: "date",
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "P /A /H",
          field: "status",
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Time Spent",
          field: "time_spent",
          formatFn: this.timespent,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Reported Hours",
          field: "get_reported_hours_details",
          thClass: "custom-th",
          tdClass: "custom-td",
          // formatFn: this.reported_hours,
        },
        {
          label: "Login Slot",
          field: "login_slot",
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Start Time",
          field: "start_time",
          formatFn: this.formatTime,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "End Time",
          field: "end_time",
          formatFn: this.formatTime,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Difference",
          field: "login_time_difference",
          formatFn: this.formatTimeDifference,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Late ? ",
          field: "has_started_late",
          formatFn: this.formatHasStartedLate,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
      ],
      rows: [],
      selectedDate: "",
      users: [],
      active_users_list: [],
      get_info: {
        total_present: 0,
        total_absent: 0,
        total_not_started: 0,
        total_users: 0,
        total_users_count: 0,
        holiday: 0,
      },
      reqObj: {
        searched_date: moment().format("yyyy-MM-DD"),
        present_absent_filter: "all",
        filtered_users: [],
      },
      dateFormat: "yyyy-MM-dd",
      disabledDates: {
        from: new Date(new Date().setDate(new Date().getDate())),
      },
      json: {
        dataSource: [],
        head: [
          "Name",
          "Date",
          "P /A /H",
          "Time Spent",
          "Report",
          "Login Slot",
          "Start Time",
          "End Time",
          "Difference",
          "Late",
        ],
        // fileName:"_Attendance.xlsx"
        fileName: "",
      },
      edited: [],
      // for dynamic reports details
      report_rows: [],
    };
  },
  watch: {
    searched_date_input_box: function (selectedDate) {
      // console.log(selectedDate);
    },
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),

    getMonthlyAttendanceReport(date) {
      var selectedDate1 = "";
      if (
        typeof this.searched_date_input_box1 != "undefined" &&
        this.searched_date_input_box1 != "" &&
        this.searched_date_input_box1 != 0
      ) {
        selectedDate1 =
          "?searched_date=" +
          moment(this.searched_date_input_box1).format("yyyy-MM-DD");
      }
      user.getMonthlyAttendanceReport(selectedDate1).then((result) => {
        // console.log("monthy",selectedDate1,result.data.data)
        this.rows1 = result.data.data.users;
      });
    },
    getUsers() {
      user.getUsers().then((result) => {
        this.users = [{ label: "Select all", users_data: result.data.data }];
        this.active_users_list = this.users.slice();
        this.userListFilter();
      });
    },
    getAttendanceReport() {
      // this.userListFilter();
      let tempObj = {
        searched_date: moment(this.reqObj.searched_date).format("yyyy-MM-DD"),
        present_absent_filter: this.reqObj.present_absent_filter,
        filtered_users: this.reqObj.filtered_users.map((item) => item.id),
      };
      return user.getAttendanceReport(tempObj).then((result) => {
        if (result.data.code == 200) {
          this.get_info.total_present = 0;
          this.get_info.total_absent = 0;
          this.get_info.total_not_started = 0;
          this.get_info.total_users = 0;
          this.rows = result.data.data.users;
          this.get_info.total_users_count = result.data.data.total_results;
          this.get_info.holiday = result.data.data.holiday;
          for (var i = 0; i < this.rows.length; i++) {
            if (this.rows[i]["start_time"] != null) {
              this.get_info.total_present++;
            }
            if (
              this.rows[i]["status"] == "-" &&
              this.rows[i]["in_leave"] != 0
            ) {
              this.get_info.total_absent++;
            }
            if (
              (this.rows[i]["start_time"] != null &&
                this.rows[i]["in_leave"] == null) ||
              this.rows[i]["in_leave"] == 0
            ) {
              this.get_info.total_not_started++;
            }
            this.get_info.total_users++;
          }
        } else {
          alert("Result not found");
        }
      });
    },
    // PDF DOWNLOAD METHOD
    newJson() {
      this.edited.length = 0;
      this.rows.forEach((obj, index) => {
        this.edited.push([]);
        this.edited[index].push(obj.full_name);
        this.edited[index].push(obj.date);
        this.edited[index].push(obj.status);
        this.edited[index].push(this.timespent(obj.time_spent));
        this.edited[index].push(obj.report_time);
        this.edited[index].push(this.formatTime(obj.login_slot));
        this.edited[index].push(this.formatTime(obj.start_time));
        this.edited[index].push(this.formatTime(obj.end_time));
        this.edited[index].push(
          this.formatTimeDifference(obj.login_time_difference)
        );
        this.edited[index].push(
          this.formatHasStartedLate(obj.has_started_late)
        );
      });
    },
    pdfDownload() {
      const doc = new jsPDF();
      // doc.page=1;
      // let count = doc.getNumberOfPages();
      // // console.log(Object.values(this.rows));
      this.newJson();
      autoTable(doc, {
        head: [this.json.head],
        body: this.edited,
        styles: { fontSize: 8, halign: "center", valign: "middle" },
        columnStyles: {
          0: { cellWidth: 45 },
          1: { cellWidth: 20 },
          2: { cellWidth: 12 },
          3: { cellWidth: 20 },
          4: { cellWidth: 14 },
          5: { cellWidth: 14 },
          6: { cellWidth: 14 },
          7: { cellWidth: 14 },
          8: { cellWidth: 14 },
          9: { cellWidth: 14 },
        },
      });
      var curr_date = moment(this.searched_date_input_box).format("yyyy-MM-DD");
      doc.save(curr_date + "_Attendance.pdf");
    },
    excelDownload() {
      var curr_date = moment(this.searched_date_input_box).format("yyyy-MM-DD");
      this.json["fileName"] = curr_date + "_Attendance.xlsx";
      // let customData={};
      // let temp=[];
      // this.json.dataSource = this.edited;
      this.edited = [];
      this.newJson();
      this.edited.unshift(this.json.head);
      let wb = utils.book_new();
      let sheet = utils.aoa_to_sheet(this.edited);
      utils.book_append_sheet(wb, sheet);
      writeFile(wb, this.json["fileName"]);
      // console.log(this.edited)
      // this.edited.forEach((arr, index) => {
      //   customData = {
      //     "Name":arr[0],
      //     "Date":arr[1],
      //     "P /A /H":arr[2],
      //     "Time Spent":arr[3],
      //     "Report":arr[4],
      //     "Login Slot":arr[5],
      //     "Start Time":arr[6],
      //     "End Time":arr[7],
      //     "Difference":arr[8],
      //     "Late":arr[9],
      //   };
      //   // console.log(customData)
      //   temp.push(customData);
      // });

      // OBJECT TEMPLATE

      // let customData = {
      //   Name: "",
      //   Username: "",
      //   Login_Slot: "",
      //   Start_Time: "",
      //   Difference: "",
      //   Late: "",
      //   End_Time: "",
      //   Time_Spent: "",
      //   Status: "",
      // };

      // console.log(temp);
      // this.json.dataSource=this.edited;
      // const { dataSource, head, fileName } = this.json;
      // console.log(this.edited);
      // jsontoexcel.getXlsx(dataSource, head, fileName);
    },
    timespent(secs) {
      if (secs == null) {
        return "-";
      } else if (secs == 0) {
        return "-";
      } else {
        var minutes = Math.floor(secs / 60);
        secs = secs % 60;
        var hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
        var second = secs;
        var time =
          (hours > 9 ? "" : "0") +
          hours +
          ":" +
          ((minutes > 9 ? "" : "0") + minutes) +
          ":" +
          ((second > 9 ? "" : "0") + second);
        return time;
      }
    },
    //concate first and last name
    concatNames: function (rowObj) {
      // console.log(rowObj);
      // console.log(rowObj.UserModel.first_name + " " + rowObj.UserModel.last_name);
      return (
        rowObj.UserModel.first_name +
        " " +
        rowObj.UserModel.last_name +
        "\n" +
        "(" +
        rowObj.UserModel.username +
        ")"
      );
    },
    formatTime: function (value) {
      var time = moment(value, "HH:mm:ss.SSS");
      var isValid = time.isValid();
      var formatttedTime = "";
      if (isValid) {
        formatttedTime = time.format("hh:mm A");
      }
      return formatttedTime;
    },
    formatTimeDifference: function (value) {
      var time = moment(value, "HH:mm");
      var isValid = time.isValid();
      var formatttedTime = "";
      if (isValid) {
        formatttedTime = time.format("HH:mm");
      }
      return formatttedTime;
    },

    //0->on time , 1 ->late, 2-> not started
    formatHasStartedLate: function (value) {
      if (value == 0) {
        return "No";
      } else if (value == 1) {
        return "Yes";
      } else if (value == 2) {
        return "Not Started";
      }
    },
    paddingZero: function (num) {
      var size = 2;
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
    hideModal() {
      $("#reportDetailsModal").modal("hide");
    },
    get_report_by_user_and_date(user_id, date) {
      user.GetReportedHoursByDate(user_id, date).then((result) => {
        if (result.data.code == 200) {
          this.report_rows = result.data.data;
          $("#reportDetailsModal").modal("show");
        }
      });
    },
    FilterApply(val) {
      if (val) {
        this.reqObj.present_absent_filter = val;
      }
      this.getAttendanceReport();
      this.userListFilter();
      // this.getUsers();
    },
    // For filtering the deactivating users from the user list
    userListFilter() {
      let temp = [];
      this.users[0]["users_data"].forEach((item) => {
        // console.log("doe ",item.doe);
        if (item.doe !== null) {
          // console.log("if-- ",this.reqObj.searched_date);
          if (new Date(this.reqObj.searched_date) <= new Date(item.doe)) {
            temp.push(item);
          }
        } else if (item.doe == null && item.is_deleted != true) {
          temp.push(item);
        }
      });
      this.active_users_list = [{ label: "Select all", users_data: temp }];
    },
    ChangeDate(val) {
      if (val == "pre") {
        let get_date = new Date(this.reqObj.searched_date);
        this.reqObj.searched_date = moment(
          new Date(get_date.setDate(get_date.getDate() - 1))
        ).format("yyyy-MM-DD");
      }
      if (val == "next") {
        let get_date = new Date(this.reqObj.searched_date);
        if (
          new Date().toISOString().slice(0, 10) >=
          moment(new Date(get_date.setDate(get_date.getDate() + 1))).format(
            "yyyy-MM-DD"
          )
        ) {
          let get_date = new Date(this.reqObj.searched_date);
          this.reqObj.searched_date = moment(
            new Date(get_date.setDate(get_date.getDate() + 1))
          ).format("yyyy-MM-DD");
        }
      }
      this.FilterApply();
    },
    custom_label(name) {
      // console.log(name.first_name,name.middle_name,name.last_name);
      // if (name.last_name != null && name.middle_name != null) {
      //   return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      // } else if (name.last_name == null) {
      //   return `${name.first_name} ${name.middle_name}`;
      // } else if (name.middle_name == null) {
      //   return `${name.first_name} ${name.last_name}`;
      //   }
      return `${name.first_name} ${name.last_name}`;
    },
  },
  mounted() {
    this.setCurrentPage("Attendance");
    this.getAttendanceReport().then(() => {
      this.getUsers();
    });
    this.getUserPermissions();
    // this.getAttendanceReportByDate(this.searched_date_input_box);
    // $(".fl-flex-label").flexLabel();
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>
<style>
.vgt-global-search {
  padding: 0 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  border: 1px solid #dcdfe6;
  border-bottom: 0;
  background: linear-gradient(#f4f5f8, #f1f3f6);
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
